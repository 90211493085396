@tailwind base;
@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
  ul, ol {
    @apply pl-3;
    @apply ml-3;
  }

  ul {
    @apply list-disc;
  }

  ol {
    @apply list-decimal;
  }
}
@tailwind components;
@tailwind utilities;

/* purgecss start ignore */
@import '~react-toastify/dist/ReactToastify.min.css';
@import '~react-image-gallery/styles/css/image-gallery.css';

@import '~react-date-range/dist/styles.css';
@import '~react-date-range/dist/theme/default.css';

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

/* purgecss end ignore */
